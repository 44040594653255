<template>
  <div class="container-fluid py-4 add-student">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3">{{$t('message.updateSubject')}} </h6>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <form @submit="updateSubject" role="form" class="text-start mt-3">
              <div class="row">
                <div class="mb-3 col-lg-6">
                  <label> {{$t('message.name')}}<span class="red">*</span></label>
                  <vmd-input
                    id=""
                    type="text"
                    v-model="subject.name"
                    placeholder="اسم المادة"
                    required
                  />
                </div>
                <div class="mb-3 col-lg-6">
                  <label class="unrequired">
                     {{$t('message.videosNum')}}<span class="green">({{$t('message.optional')}})</span></label
                  >
                  <vmd-input
                    id=""
                    type="number"
                    v-model="subject.number_of_videos"
                    :placeholder=" $t('message.videosNum') "
                  />
                </div>
              </div>

              <div class="row">
                <div class="mb-3 col-lg-6">
                  <label class="unrequired">
                     {{$t('message.value')}}<span class="green">({{$t('message.optional')}})</span></label
                  >
                  <vmd-input
                    id=""
                    type="number"
                    v-model="subject.cost"
                    :placeholder="$t('message.value') "
                  />
                </div>
                 <div class="mb-3 col-lg-6">
                  <label>
                    {{$t('message.department')}}<span class="red">*</span>
                    <span color="danger" class="error">{{
                      errors.department_id ? errors.department_id[0] : ''
                    }}</span>
                  </label>
                  <multiselect
                        class='required form-control'
                        id='group'
                        v-model="selectedDepartment"
                        :options='departments'
                        :searchable='true'
                        :close-on-select='true'
                        :placeholder="$t('message.select') "
                        label='name'
                        :custom-label="customLabel"
                        track-by='id'
                        required='required'
                        :internal-search="true"
                        >
                      >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                </div>
              </div>
              <div class="row">
                <div class="text-center col-lg-6">
                  <vmd-button class="my-4 mb-2" variant="gradient" color="info">
                    {{$t('message.save')}}</vmd-button
                  >
                </div>
                <div class="text-center col-lg-6">
                  <router-link to="/subjects">
                    <vmd-button
                      type="reset"
                      class="my-4 mb-2"
                      variant="gradient"
                      color="warning"
                    >
                      {{$t('message.back')}}</vmd-button
                    >
                  </router-link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VmdInput from '@/components/VmdInput.vue'
import VmdButton from '@/components/VmdButton.vue'
import swal from 'sweetalert'

export default {
  name: 'EditSubject',
  components: {
    VmdInput,
    VmdButton
  },
  data () {
    return {
      subject: {},
      departments: [],
      selectedDepartment: '',
      errors: []
    }
  },
  created () {
    console.log(this.$route.params.id)
    this.$http
      .get(`${this.$hostUrl}subjects/${this.$route.params.id}`)
      .then((response) => {
        this.subject = response.data.data
        this.selectedDepartment = {
          id: this.subject?.department?.id,
          name: this.subject?.department?.name
        }
      })
    this.$http
      .get(`${this.$hostUrl}departments`)
      .then((response) => {
        this.departments = response.data.data
      })
  },
  methods: {
    async updateSubject (e) {
      e.preventDefault()
      const formData = new FormData()
      if (/^\d+$/.test(this.subject.name)) {
        swal('اسم المادة يجب ان يحتوي على حروف')
        return 0
      }
      formData.append('name', this.subject.name)
      formData.append('name_en', this.subject.name)
      formData.append('department_id', this.selectedDepartment.id)
      if (this.subject.number_of_videos) {
        formData.append('number_of_videos', this.subject.number_of_videos)
      }
      if (this.subject.cost) {
        formData.append('cost', this.subject.cost)
      }
      formData.append('_method', 'PUT')

      this.errors = await this.postRequest(
        formData,
        this.$hostUrl + 'subjects/' + this.$route.params.id,
        '/subjects'
      )
    }
  }
}
</script>
